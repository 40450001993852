import React from 'react'
import { type IMerchModule } from '@pcln/contentful-components'
import MerchModuleWrapper from '.'
import LayoutComponentWrapper from '../LayoutComponentWrapper'

function PromoBanners({
  merchModules
}: {
  merchModules: ReadonlyArray<IMerchModule>
}) {
  return merchModules.length > 0 ? (
    <LayoutComponentWrapper>
      {merchModules.map(merchModule => {
        return (
          <React.Fragment key={merchModule?.internalName}>
            <MerchModuleWrapper content={merchModule} />
          </React.Fragment>
        )
      })}
    </LayoutComponentWrapper>
  ) : null
}

export default PromoBanners
