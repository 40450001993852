import React from 'react'
import styled from 'styled-components'
import { Flex, Layout } from 'pcln-design-system'
import { ActionCard } from '@pcln/cards'
import { Cars, Discount, Help, Phone, Verified } from 'pcln-icons'

const StyledActionCard = styled(ActionCard)`
  height: 100%;
`
type Content = {
  heading: string
  href?: string
  iconName: 'Verified' | 'Discount' | 'Cars' | 'Help' | 'Phone'
  subtext: React.ReactNode
}

type Banner = {
  content: Content[]
}

export default function WhyPricelineBanner({ content }: Banner) {
  const ICONS = {
    Cars,
    Discount,
    Help,
    Phone,
    Verified
  }

  return (
    <Flex>
      <Layout
        variation={['50-50', undefined, undefined, undefined, '25-25-25-25']}
        rowGap="md"
        gap="md"
      >
        {content &&
          content.map(tileContent => {
            const ICON = ICONS[tileContent.iconName]
            const handleOnClick = () => {
              window.open(tileContent.href)
            }

            return (
              <StyledActionCard
                key={tileContent.iconName}
                icon={<ICON size="24px" />}
                heading={tileContent.heading}
                caption={tileContent.subtext}
                onClick={tileContent.href ? handleOnClick : undefined}
                handleSubmitCTA={tileContent.href ? handleOnClick : undefined}
              />
            )
          })}
      </Layout>
    </Flex>
  )
}
