import React, { useState } from 'react'
import styled from 'styled-components'
import { Box, Card, Container, Flex, theme, Hide } from 'pcln-design-system'
import { Gpt } from '@pcln/ad-unit'
import StyledHeading from '@/components/StyledHeading'
import HeroImageParallax from '@/components/Heros/HeroImageParallax'
import useExpressDealDialog from '@/hooks/useExpressDealDialog'
import useContentfulSearchFormHeadlines from '@/hooks/useContentfulSearchFormHeadlines'
import { LOCATION_SEARCH_TYPE } from '@/types'
import SearchFormProvider from '@/context/SearchFormContext'
import useSeti from '@/hooks/useSeti'
import { ANALYTICS_CATEGORY_HOME } from '@/constants/analytics'
import HotelsContent from './HotelsContent'
import { handleSubmitForHotels } from './SearchForm/utils'
import Form from './SearchForm/Form'
import UrgencyMessage from './UrgencyMessage'
import FormHeadline from './FormHeadline'
import { HotelsBumper } from '../SearchForm/Bumpers'
import ExpressDealDialog from './ExpressDeal/ExpressDealDialog'

const BlockFlex = styled(Box)`
  position: relative;
  z-index: 1;
  ${theme.mediaQueries[3]} {
    display: flex;
    align-items: flex-start;
  }
`

const HideAdUnit = styled(Hide)`
  text-align: center;
  position: relative;
  margin: 16px;
  min-height: 100px;
  ${theme.mediaQueries[3]} {
    width: 300px;
    flex: none;
    margin: 0 16px;
  }
`

function HotelsDesktop() {
  const { isOpen, closeDialog } = useExpressDealDialog()
  const [location, setLocation] = useState<LOCATION_SEARCH_TYPE | undefined>()
  const searchForm = useContentfulSearchFormHeadlines('searchForm')
  const showMultiHotel =
    useSeti('PKG_FE_HH_HOTEL_LANDING_PAGE', !isOpen) === 'VARIANT' && !isOpen
  const subheadline = searchForm?.subheadline ?? ''
  return (
    <Box>
      <ExpressDealDialog
        isOpen={isOpen}
        closeDialog={closeDialog}
        gaCategory={ANALYTICS_CATEGORY_HOME}
      />
      <HeroImageParallax
        isScrollable
        gptId="div-gpt-ad-1509978154282-0"
        slot="/3102/priceline.dart/hotel_homepage_hero_image"
      >
        <Container>
          <BlockFlex mx={3} py={[3, null, null, null, 5]}>
            <Card
              width={1}
              borderWidth={0}
              color="background.lightest"
              borderRadius="2xl"
              boxShadowSize="xl"
            >
              <SearchFormProvider>
                <Box p={3} width={1}>
                  <Flex pb={1}>
                    <FormHeadline location={location} />
                    <UrgencyMessage location={location} my="auto" ml={3} />
                  </Flex>
                  <StyledHeading
                    pb={3}
                    tag="h2"
                    color="text.light"
                    text={subheadline}
                    textStyle={['subheading6', null, null, null, 'subheading4']}
                  />
                  <Form
                    onSubmit={handleSubmitForHotels}
                    setLocation={setLocation}
                    showMultiHotel={showMultiHotel}
                  />
                </Box>
                <HotelsBumper showMultiHotel={showMultiHotel} />
              </SearchFormProvider>
            </Card>
            <HideAdUnit xs sm>
              <Gpt render={() => <div id="div-gpt-ad-1510170073577-0" />} />
            </HideAdUnit>
          </BlockFlex>
        </Container>
      </HeroImageParallax>
      <Hide xs sm color="background.lightest">
        <HotelsContent />
      </Hide>
    </Box>
  )
}

export default HotelsDesktop
