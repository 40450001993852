import React, { useState } from 'react'
import { Container, Box } from 'pcln-design-system'
import { useContentfulWithPredicate } from '@pcln/contentful-components'
import AdBanner from '@/components/AdBanner'
import { AdBox, AdContent } from '@/components/AdContent'
import AppDownloadWrapper from '@/components/AppDownloadWrapper'
import SEOLinks from '@/components/SEOLinks'
import EmailSignUp from '@/components/EmailSignUp'
import { ANALYTICS_CATEGORY_HOTELS } from '@/constants/analytics'
import SEOPRODUCTLINKS from '@/constants/seo-product-links'
import WhyPricelineBanner from '@/components/WhyPricelineBanner'
import { REASONS_HOTELS_LP } from '@/constants/reasons'
import PromoBanners from '@/components/MerchModuleWrapper/PromoBanners'
import TripActivity from '@/components/TripActivity'
import { isCollectionOfIMerchModule } from '@/shared-utils/content-model-type-guards'
import LayoutComponentWrapper from '@/components/LayoutComponentWrapper'
import useBootstrapData from '@/hooks/useBootstrapData'
import LinksWidget from '@/components/SkyMondeSeo/LinksWidget'
import ExpressDealPromo from '../ExpressDeal/ExpressDealPromo'

const { GLOBAL, USA, EUROPE, ASIA, INTERNATIONAL } =
  SEOPRODUCTLINKS.HOTELS.links.exploreDeals

function HotelsContent() {
  const toutBanners = useContentfulWithPredicate(
    'toutBanners',
    isCollectionOfIMerchModule
  )
  const promoItems = toutBanners?.items || ([] as const)
  const [isTripActivityVisible, setIsTripActivityVisible] = useState(false)
  const { skymondeSEO } = useBootstrapData()

  return (
    <Container size="xl" mb={5}>
      <Box mt={[0, null, 4]}>
        <TripActivity
          setIsVisible={setIsTripActivityVisible}
          productType={['STAY']}
          analyticsCategory={ANALYTICS_CATEGORY_HOTELS}
        />
      </Box>
      <LayoutComponentWrapper topGap>
        {!isTripActivityVisible && (
          <WhyPricelineBanner content={REASONS_HOTELS_LP} />
        )}
        <AdContent>
          <AdBox type="primary">
            <div id="div-gpt-ad-1509978848821-0" />
          </AdBox>
          <AdBox type="secondary">
            <div id="div-gpt-ad-1509978930813-0" />
          </AdBox>
          <AdBox type="tertiary">
            <div id="div-gpt-ad-1509129580404-0" />
          </AdBox>
        </AdContent>
        <EmailSignUp
          sourceId="LANDING_HOTEL"
          category={ANALYTICS_CATEGORY_HOTELS}
        />
        <ExpressDealPromo />
        <PromoBanners merchModules={promoItems} />
        <Box>
          <AppDownloadWrapper category={ANALYTICS_CATEGORY_HOTELS} />
        </Box>
        <AdBanner>
          <div id="div-gpt-ad-1509129866793-0" />
        </AdBanner>
        {skymondeSEO ? (
          <LinksWidget data={skymondeSEO} />
        ) : (
          <>
            {[USA, EUROPE, ASIA, INTERNATIONAL, GLOBAL].map(location => (
              <SEOLinks
                key={location.action}
                caption={location.caption}
                category={ANALYTICS_CATEGORY_HOTELS}
                gaAction={location.action}
                links={location.items}
              />
            ))}
          </>
        )}
      </LayoutComponentWrapper>
    </Container>
  )
}

export default HotelsContent
