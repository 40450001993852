import React from 'react'
import { Box, Button, Flex, Heading, Text } from 'pcln-design-system'
import useExpressDealDialog from '@/hooks/useExpressDealDialog'

export default function ExpressDealPromo() {
  const { openDialog } = useExpressDealDialog()
  return (
    <Box boxShadowSize="sm" p={4} borderRadius="xl">
      <Heading
        bold
        textAlign="center"
        pb={2}
        color="text.heading"
        textStyle="heading2"
      >
        Save up to 60% on your next hotel
      </Heading>

      <Text textAlign="center" pb={3} fontSize={3} color="text.base">
        Get fast, easy savings on your next hotel with Priceline-exclusive
        Express Deals. Save up to 60% on your next hotel. Book and save!
      </Text>
      <Flex justifyContent="center">
        <Button
          variation="subtle"
          size="large"
          data-testid="EXPRESS_DEAL_PROMO_BUTTON"
          onClick={openDialog}
        >
          Search Express Deals
        </Button>
      </Flex>
    </Box>
  )
}
