import React from 'react'

const FLEXIBLE_BOOKING_TILE = {
  iconName: 'Verified' as const,
  heading: 'Flexible Bookings',
  subtext:
    'Plans change. That’s why we offer free cancellation on most hotels & rental cars.'
}

const DISCOUNT_TILE = {
  iconName: 'Discount' as const,
  heading: 'Incredible Deals',
  subtext:
    'Check out with confidence. Priceline members always get our best price.',
  href: 'https://help.priceline.com/what-is-best-price-guarantee-Sys6cJ86u'
}

const DISCOUNT_HOTELS_LP_TILE = {
  iconName: 'Discount' as const,
  heading: 'Book With Confidence',
  subtext: 'Priceline members always get our best price.',
  href: 'https://help.priceline.com/what-is-best-price-guarantee-Sys6cJ86u'
}

const RC_TILE = {
  iconName: 'Cars' as const,
  heading: 'No Flight? No Problem',
  subtext:
    'Bundle hotel & rental car deals to build your perfect getaway. No airtime required.'
}

const PHONE_HOTELS_LP_VARIANT_TILE = {
  iconName: 'Phone' as const,
  heading: 'Unlock Special Discounts',
  subtext: (
    <>
      1 in 4 people save 20% or more over the phone. Call Now.
      <br />
      (833) 378-0470
    </>
  )
}

const HELP_TILE = {
  iconName: 'Help' as const,
  heading: 'Help 24/7',
  subtext:
    'We’re always here for you – reach us 24 hours a day, 7 days a week.',
  href: 'https://help.priceline.com'
}

export const REASONS_HOME = [
  FLEXIBLE_BOOKING_TILE,
  RC_TILE,
  DISCOUNT_TILE,
  HELP_TILE
]
export const REASONS_HOTELS_LP = [
  FLEXIBLE_BOOKING_TILE,
  PHONE_HOTELS_LP_VARIANT_TILE,
  DISCOUNT_HOTELS_LP_TILE,
  HELP_TILE
]
