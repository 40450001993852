import React from 'react'
import styled from 'styled-components'
import { Box, Flex, Link, Text } from 'pcln-design-system'
import analytics from '@/shared-utils/analytics'

const LinkFlex = styled(Flex)`
  flex-grow: 1;
  flex-basis: 340px;
  flex-shrink: 1;
`

type Link = {
  href: string
  text: string
}

export default function SEOLinks({
  caption,
  category,
  gaAction,
  links
}: {
  caption: string
  category: string
  gaAction: string
  links: Link[]
}) {
  return (
    <Box>
      <Text fontSize={3} fontWeight="bold" mb={3} textAlign="left">
        {caption}
      </Text>
      <Flex flexWrap="wrap">
        {links.map((link, index) => (
          <LinkFlex key={`SEO-Flex-${index + 1}`}>
            {link.href?.length > 0 && link.text?.length > 0 ? (
              <Link
                href={link.href}
                onClick={() => {
                  analytics.fireEvent({
                    action: gaAction,
                    category,
                    label: link.text
                  })
                }}
              >
                <Text fontSize={1} mb={2}>
                  {link.text}
                </Text>
              </Link>
            ) : null}
          </LinkFlex>
        ))}
      </Flex>
    </Box>
  )
}
