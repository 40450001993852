export default {
  HOTELS: {
    name: 'HOTEL',
    links: {
      exploreDeals: {
        USA: {
          caption: 'Top Hotel Deals in the United States',
          section: 1,
          action: 'Cheap Hotels',
          items: [
            {
              href: '/hotel-deals/c3000001438/US/California/Anaheim-Hotels.html',
              text: 'Anaheim Hotels'
            },
            {
              href: '/hotel-deals/c3000020845/US/Texas/Arlington-Hotels.html',
              text: 'Arlington Hotels'
            },
            {
              href: '/hotel-deals/c3000003496/US/Georgia/Atlanta-Hotels.html',
              text: 'Atlanta Hotels'
            },
            {
              href: '/hotel-deals/c3000020856/US/Texas/Austin-Hotels.html',
              text: 'Austin Hotels'
            },
            {
              href: '/hotel-deals/c3000008602/US/Massachusetts/Boston-Hotels.html',
              text: 'Boston Hotels'
            },
            {
              href: '/hotel-deals/c3000012874/US/North-Carolina/Charlotte-Hotels.html',
              text: 'Charlotte Hotels'
            },
            {
              href: '/hotel-deals/c3000005381/US/Illinois/Chicago-Hotels.html',
              text: 'Chicago Hotels'
            },
            {
              href: '/hotel-deals/c3000016809/US/Ohio/Columbus-Hotels.html',
              text: 'Columbus Hotels'
            },
            {
              href: '/hotel-deals/c3000021082/US/Texas/Dallas-Hotels.html',
              text: 'Dallas Hotels'
            },
            {
              href: '/hotel-deals/c3000002573/US/Colorado/Denver-Hotels.html',
              text: 'Denver Hotels'
            },
            {
              href: '/hotel-deals/c3000021312/US/Texas/Houston-Hotels.html',
              text: 'Houston Hotels'
            },
            {
              href: '/hotel-deals/c3000006556/US/Indiana/Indianapolis-Hotels.html',
              text: 'Indianapolis Hotels'
            },
            {
              href: '/hotel-deals/c3000003249/US/Florida/Jacksonville-Hotels.html',
              text: 'Jacksonville Hotels'
            },
            {
              href: '/hotel-deals/c3000015284/US/Nevada/Las-Vegas-Hotels.html',
              text: 'Las Vegas Hotels'
            },
            {
              href: '/hotel-deals/c3000001947/US/California/Los-Angeles-Hotels.html',
              text: 'Los Angeles Hotels'
            },
            {
              href: '/hotel-deals/c3000003311/US/Florida/Miami-Hotels.html',
              text: 'Miami Hotels'
            },
            {
              href: '/hotel-deals/c3000020633/US/Tennessee/Nashville-Hotels.html',
              text: 'Nashville Hotels'
            },
            {
              href: '/hotel-deals/c3000008434/US/Louisiana/New-Orleans-Hotels.html',
              text: 'New Orleans Hotels'
            },
            {
              href: '/hotel-deals/c3000016152/US/New-York/New-York-Hotels.html',
              text: 'New York Hotels'
            },
            {
              href: '/hotel-deals/c3000003349/US/Florida/Orlando-Hotels.html',
              text: 'Orlando Hotels'
            },
            {
              href: '/hotel-deals/r46/US/Pennsylvania-Hotels.html',
              text: 'Philadelphia Hotels'
            },
            {
              href: '/hotel-deals/c3000001349/US/Arizona/Phoenix-Hotels.html',
              text: 'Phoenix Hotels'
            },
            {
              href: '/hotel-deals/c3000018178/US/Oregon/Portland-Hotels.html',
              text: 'Portland Hotels'
            },
            {
              href: '/hotel-deals/c3000011975/US/Missouri/Saint-Louis-Hotels.html',
              text: 'Saint Louis Hotels'
            },
            {
              href: '/hotel-deals/c3000021763/US/Texas/San-Antonio-Hotels.html',
              text: 'San Antonio Hotels'
            },
            {
              href: '/hotel-deals/c3000002241/US/California/San-Diego-Hotels.html',
              text: 'San Diego Hotels'
            },
            {
              href: '/hotel-deals/c3000002244/US/California/San-Francisco-Hotels.html',
              text: 'San Francisco Hotels'
            },
            {
              href: '/hotel-deals/c3000023414/US/Washington/Seattle-Hotels.html',
              text: 'Seattle Hotels'
            },
            {
              href: '/hotel-deals/c3000003431/US/Florida/Tampa-Hotels.html',
              text: 'Tampa Hotels'
            },
            {
              href: '/hotel-deals/c3000003032/US/District-Of-Columbia/Washington-Hotels.html',
              text: 'Washington Hotels'
            }
          ]
        },
        EUROPE: {
          caption: 'Top Hotel Deals in Europe',
          section: 2,
          action: 'Cheap Hotels',
          items: [
            {
              href: '/hotel-deals/c3000035824/NL/Noord-Holland/Amsterdam-Hotels.html',
              text: 'Amsterdam Hotels'
            },
            {
              href: '/hotel-deals/c3000035889/GR/Greece/Athens-Hotels.html',
              text: 'Athens Hotels'
            },
            {
              href: '/hotel-deals/c3000035833/SP/Catalu-a/Barcelona-Hotels.html',
              text: 'Barcelona Hotels'
            },
            {
              href: '/hotel-deals/c3000035821/GM/Berlin/Berlin-Hotels.html',
              text: 'Berlin Hotels'
            },
            {
              href: '/hotel-deals/c3000035826/EI/Ireland/Dublin-Hotels.html',
              text: 'Dublin Hotels'
            },
            {
              href: '/hotel-deals/c3000035830/UK/City-Of-Edinburgh-Scotland/Edinburgh-Hotels.html',
              text: 'Edinburgh Hotels'
            },
            {
              href: '/hotel-deals/c3000035879/IT/Toscana-Tuscany-/Florence-Hotels.html',
              text: 'Florence Hotels'
            },
            {
              href: '/hotel-deals/c3000035831/GM/Hessen/Frankfurt-Hotels.html',
              text: 'Frankfurt Hotels'
            },
            {
              href: '/hotel-deals/c5000436138/GB/England/Hillingdon-Hotels.html',
              text: 'Hillingdon Hotels'
            },
            {
              href: '/hotel-deals/c3000035890/PO/Portugal/Lisbon-Hotels.html',
              text: 'Lisbon Hotels'
            },
            {
              href: '/hotel-deals/c3000035825/UK/England/London-Hotels.html',
              text: 'London Hotels'
            },
            {
              href: '/hotel-deals/c3000035834/SP/Comunidad-De-Madrid/Madrid-Hotels.html',
              text: 'Madrid Hotels'
            },
            {
              href: '/hotel-deals/c3000035829/IT/Lombardia/Milan-Hotels.html',
              text: 'Milan Hotels'
            },
            {
              href: '/hotel-deals/c3000035919/GM/Bayern/Munich-Hotels.html',
              text: 'Munich Hotels'
            },
            {
              href: '/hotel-deals/c3000035827/FR/Paris/Paris-Hotels.html',
              text: 'Paris Hotels'
            },
            {
              href: '/hotel-deals/c3000035883/CZ/Stredocesky/Prague-Hotels.html',
              text: 'Prague Hotels'
            },
            {
              href: '/hotel-deals/c3000035839/FR/Val-Doise/Roissy-Hotels.html',
              text: 'Roissy Hotels'
            },
            {
              href: '/hotel-deals/c3000035823/IT/Lazio/Rome-Hotels.html',
              text: 'Rome Hotels'
            },
            {
              href: '/hotel-deals/c3000035822/IT/Veneto/Venice-Hotels.html',
              text: 'Venice Hotels'
            },
            {
              href: '/hotel-deals/c3000035894/SZ/Zurich/Zurich-Hotels.html',
              text: 'Zurich Hotels'
            },
            {
              href: '/hotel-deals/c3000035886/AT/Wien/Vienna-Hotels.html',
              text: 'Vienna Hotels'
            }
          ]
        },
        ASIA: {
          caption: 'Top Hotel Deals in Asia',
          section: 3,
          action: 'Cheap Hotels',
          items: [
            {
              href: '/hotel-deals/c3000040033/TH/Krung-Thep-Mahanakhon/Bangkok-Hotels.html',
              text: 'Bangkok Hotels'
            },
            {
              href: '/hotel-deals/c3000040013/CH/Beijing/Beijing-Hotels.html',
              text: 'Beijing Hotels'
            },
            {
              href: '/hotel-deals/c5000490977/TH/Chiang-Mai/Chiang-Mai-Hotels.html',
              text: 'Chiang Mai Hotels'
            },
            {
              href: '/hotel-deals/c5000003658/TC/Dubayy/Dubai-Hotels.html',
              text: 'Dubai Hotels'
            },
            {
              href: '/hotel-deals/c5000466043/VM/Ho-Chi-Minh/Ho-Chi-Minh-City-Hotels.html',
              text: 'Ho Chi Minh City Hotels'
            },
            {
              href: '/hotel-deals/c3000040012/HK/Hong-Kong-Sar/Hong-Kong-Hotels.html',
              text: 'Hong Kong Hotels'
            },
            {
              href: '/hotel-deals/c3000040045/TU/Istanbul/Istanbul-Hotels.html',
              text: 'Istanbul Hotels'
            },
            {
              href: '/hotel-deals/c3000040026/ID/Jakarta-Raya/Jakarta-Hotels.html',
              text: 'Jakarta Hotels'
            },
            {
              href: '/hotel-deals/c3000040022/MY/Wilayah-Persekutuan-Kuala-Lumpur/Kuala-Lumpur-Hotels.html',
              text: 'Kuala Lumpur Hotels'
            },
            {
              href: '/hotel-deals/c5000479776/JA/Kyoto/Kyoto-Hotels.html',
              text: 'Kyoto Hotels'
            },
            {
              href: '/hotel-deals/c3000040031/RP/Metro-Manila/Manila-Hotels.html',
              text: 'Manila Hotels'
            },
            {
              href: '/hotel-deals/c5000494939/IN/Maharashtra/Mumbai-Hotels.html',
              text: 'Mumbai Hotels'
            },
            {
              href: '/hotel-deals/c3000040029/IN/Delhi/New-Delhi-Hotels.html',
              text: 'New Delhi Hotels'
            },
            {
              href: '/hotel-deals/c3000040036/JA/Osaka/Osaka-Hotels.html',
              text: 'Osaka Hotels'
            },
            {
              href: '/hotel-deals/c3000040034/TH/Phuket/Phuket-Hotels.html',
              text: 'Phuket Hotels'
            },
            {
              href: '/hotel-deals/c3000040019/KS/Seoul/Seoul-Hotels.html',
              text: 'Seoul Hotels'
            },
            {
              href: '/hotel-deals/c3000040015/CH/Shanghai/Shanghai-Hotels.html',
              text: 'Shanghai Hotels'
            },
            {
              href: '/hotel-deals/c3000040021/SN/Singapore/Singapore-Hotels.html',
              text: 'Singapore Hotels'
            },
            {
              href: '/hotel-deals/c3000040018/TW/T-ai-Pei-Chuen-Shih/Taipei-Hotels.html',
              text: 'Taipei Hotels'
            },
            {
              href: '/hotel-deals/c3000040035/JA/Tokyo/Tokyo-Hotels.html',
              text: 'Tokyo Hotels'
            },
            {
              href: '',
              text: ''
            }
          ]
        },
        INTERNATIONAL: {
          caption: 'Top International Hotel Deals',
          section: 4,
          action: 'Cheap Hotels',
          items: [
            {
              href: '/hotel-deals/c3000031041/CA/Alberta/Banff-Hotels.html',
              text: 'Banff Hotels'
            },
            {
              href: '/hotel-deals/c3000031085/CA/Alberta/Calgary-Hotels.html',
              text: 'Calgary Hotels'
            },
            {
              href: '/hotel-deals/c3000061781/MX/Quintana-Roo/Cancun-Hotels.html',
              text: 'Cancun Hotels'
            },
            {
              href: '/hotel-deals/c3000031161/CA/Alberta/Edmonton-Hotels.html',
              text: 'Edmonton Hotels'
            },
            {
              href: '/hotel-deals/c3000035653/CA/Nova-Scotia/Halifax-Hotels.html',
              text: 'Halifax Hotels'
            },
            {
              href: '/hotel-deals/c3000033081/CA/Ontario/London-Hotels.html',
              text: 'London Hotels'
            },
            {
              href: '/hotel-deals/c3000033109/CA/Ontario/Markham-Hotels.html',
              text: 'Markham Hotels'
            },
            {
              href: '/hotel-deals/c3000040001/AS/Victoria/Melbourne-Hotels.html',
              text: 'Melbourne Hotels'
            },
            {
              href: '/hotel-deals/c3000033144/CA/Ontario/Mississauga-Hotels.html',
              text: 'Mississauga Hotels'
            },
            {
              href: '/hotel-deals/c3000033184/CA/Ontario/Niagara-Falls-Hotels.html',
              text: 'Niagara Falls Hotels'
            },
            {
              href: '/hotel-deals/c3000033237/CA/Ontario/Ottawa-Hotels.html',
              text: 'Ottawa Hotels'
            },
            {
              href: '/hotel-deals/c3000030161/CA/Quebec/Quebec-City-Hotels.html',
              text: 'Quebec City Hotels'
            },
            {
              href: '/hotel-deals/c3000030844/CA/British-Columbia/Richmond-Hotels.html',
              text: 'Richmond Hotels'
            },
            {
              href: '/hotel-deals/c3000032216/CA/Saskatchewan/Saskatoon-Hotels.html',
              text: 'Saskatoon Hotels'
            },
            {
              href: '/hotel-deals/c3000040000/AS/New-South-Wales/Sydney-Hotels.html',
              text: 'Sydney Hotels'
            },
            {
              href: '/hotel-deals/c3000033452/CA/Ontario/Toronto-Hotels.html',
              text: 'Toronto Hotels'
            },
            {
              href: '/hotel-deals/c3000030999/CA/British-Columbia/Vancouver-Hotels.html',
              text: 'Vancouver Hotels'
            },
            {
              href: '/hotel-deals/c3000031000/CA/British-Columbia/Victoria-Hotels.html',
              text: 'Victoria Hotels'
            },
            {
              href: '/hotel-deals/c3000032664/CA/Manitoba/Winnipeg-Hotels.html',
              text: 'Winnipeg Hotels'
            }
          ]
        },
        GLOBAL: {
          caption: 'Top Global Hotel Deals',
          section: 5,
          action: 'Cheap Hotels',
          items: [
            {
              href: '/hotel-deals/k218/Albania-Hotels.html',
              text: 'Albania Hotels'
            },
            {
              href: '/hotel-deals/k54/Algeria-Hotels.html',
              text: 'Algeria Hotels'
            },
            {
              href: '/hotel-deals/k170/Andorra-Hotels.html',
              text: 'Andorra Hotels'
            },
            {
              href: '/hotel-deals/k192/Anguilla-Hotels.html',
              text: 'Anguilla Hotels'
            },
            {
              href: '/hotel-deals/k186/Antigua-and-Barbuda-Hotels.html',
              text: 'Antigua and Barbuda Hotels'
            },
            {
              href: '/hotel-deals/k124/Argentina-Hotels.html',
              text: 'Argentina Hotels'
            },
            {
              href: '/hotel-deals/k130/Armenia-Hotels.html',
              text: 'Armenia Hotels'
            },
            {
              href: '/hotel-deals/k236/Aruba-Hotels.html',
              text: 'Aruba Hotels'
            },
            {
              href: '/hotel-deals/k3/Australia-Hotels.html',
              text: 'Australia Hotels'
            },
            {
              href: '/hotel-deals/k11/Austria-Hotels.html',
              text: 'Austria Hotels'
            },
            {
              href: '/hotel-deals/k210/Azerbaijan-Hotels.html',
              text: 'Azerbaijan Hotels'
            },
            {
              href: '/hotel-deals/k13/Bahamas-Hotels.html',
              text: 'Bahamas Hotels'
            },
            {
              href: '/hotel-deals/k68/Bahrain-Hotels.html',
              text: 'Bahrain Hotels'
            },
            {
              href: '/hotel-deals/k74/Bangladesh-Hotels.html',
              text: 'Bangladesh Hotels'
            },
            {
              href: '/hotel-deals/k144/Barbados-Hotels.html',
              text: 'Barbados Hotels'
            },
            {
              href: '/hotel-deals/k121/Belarus-Hotels.html',
              text: 'Belarus Hotels'
            },
            {
              href: '/hotel-deals/k7/Belgium-Hotels.html',
              text: 'Belgium Hotels'
            },
            {
              href: '/hotel-deals/k102/Belize-Hotels.html',
              text: 'Belize Hotels'
            },
            {
              href: '/hotel-deals/k194/Bermuda-Hotels.html',
              text: 'Bermuda Hotels'
            },
            {
              href: '/hotel-deals/k132/Bolivia-Hotels.html',
              text: 'Bolivia Hotels'
            },
            {
              href: '/hotel-deals/k185/Bonaire-Sint-Eustatius-and-Saba-Hotels.html',
              text: 'Bonaire, Sint Eustatius and Saba Hotels'
            },
            {
              href: '/hotel-deals/k223/Bosnia-and-Herzegovina-Hotels.html',
              text: 'Bosnia and Herzegovina Hotels'
            },
            {
              href: '/hotel-deals/k140/Botswana-Hotels.html',
              text: 'Botswana Hotels'
            },
            {
              href: '/hotel-deals/k115/Brazil-Hotels.html',
              text: 'Brazil Hotels'
            },
            {
              href: '/hotel-deals/k193/British-Virgin-Islands-Hotels.html',
              text: 'British Virgin Islands Hotels'
            },
            {
              href: '/hotel-deals/k106/Brunei-Hotels.html',
              text: 'Brunei Hotels'
            },
            {
              href: '/hotel-deals/k213/Bulgaria-Hotels.html',
              text: 'Bulgaria Hotels'
            },
            {
              href: '/hotel-deals/k164/Cambodia-Hotels.html',
              text: 'Cambodia Hotels'
            },
            {
              href: '/hotel-deals/k39/Cameroon-Hotels.html',
              text: 'Cameroon Hotels'
            },
            {
              href: '/hotel-deals/k2/Canada-Hotels.html',
              text: 'Canada Hotels'
            },
            {
              href: '/hotel-deals/k226/Cayman-Islands-Hotels.html',
              text: 'Cayman Islands Hotels'
            },
            {
              href: '/hotel-deals/k114/Chile-Hotels.html',
              text: 'Chile Hotels'
            },
            {
              href: '/hotel-deals/k18/China-Hotels.html',
              text: 'China Hotels'
            },
            {
              href: '/hotel-deals/k42/Colombia-Hotels.html',
              text: 'Colombia Hotels'
            },
            {
              href: '/hotel-deals/k137/Congo-Hotels.html',
              text: 'Congo Hotels'
            },
            {
              href: '/hotel-deals/k198/Cook-Islands-Hotels.html',
              text: 'Cook Islands Hotels'
            },
            {
              href: '/hotel-deals/k43/Costa-Rica-Hotels.html',
              text: 'Costa Rica Hotels'
            },
            {
              href: '/hotel-deals/k146/Croatia-Hotels.html',
              text: 'Croatia Hotels'
            },
            {
              href: '/hotel-deals/k184/Curacao-Hotels.html',
              text: 'Curacao Hotels'
            },
            {
              href: '/hotel-deals/k84/Cyprus-Hotels.html',
              text: 'Cyprus Hotels'
            },
            {
              href: '/hotel-deals/k117/Czech-Republic-Hotels.html',
              text: 'Czech Republic Hotels'
            },
            {
              href: '/hotel-deals/k16/Denmark-Hotels.html',
              text: 'Denmark Hotels'
            },
            {
              href: '/hotel-deals/k100/Djibouti-Hotels.html',
              text: 'Djibouti Hotels'
            },
            {
              href: '/hotel-deals/k29/Dominican-Republic-Hotels.html',
              text: 'Dominican Repubic Hotels'
            },
            {
              href: '/hotel-deals/k44/Ecuador-Hotels.html',
              text: 'Ecuador Hotels'
            },
            {
              href: '/hotel-deals/k47/Egypt-Hotels.html',
              text: 'Egypt Hotels'
            },
            {
              href: '/hotel-deals/k82/El-Salvador-Hotels.html',
              text: 'El Salvador Hotels'
            },
            {
              href: '/hotel-deals/k219/Estonia-Hotels.html',
              text: 'Estonia Hotels'
            },
            {
              href: '/hotel-deals/k50/Ethiopia-Hotels.html',
              text: 'Ethiopia Hotels'
            },
            {
              href: '/hotel-deals/k143/Faroe-Islands-Hotels.html',
              text: 'Faroe Islands Hotels'
            },
            {
              href: '/hotel-deals/k178/Fiji-Hotels.html',
              text: 'Fiji Hotels'
            },
            {
              href: '/hotel-deals/k31/Finland-Hotels.html',
              text: 'Finland Hotels'
            },
            {
              href: '/hotel-deals/k9/France-Hotels.html',
              text: 'France Hotels'
            },
            {
              href: '/hotel-deals/k149/French-Guiana-Hotels.html',
              text: 'French Guiana Hotels'
            },
            {
              href: '/hotel-deals/k197/French-Polynesia-Hotels.html',
              text: 'French Polynesia Hotels'
            },
            {
              href: '/hotel-deals/k127/Gabon-Hotels.html',
              text: 'Gabon Hotels'
            },
            {
              href: '/hotel-deals/k157/Gambia-Hotels.html',
              text: 'Gambia Hotels'
            },
            {
              href: '/hotel-deals/k109/Georgia-Hotels.html',
              text: 'Georgia Hotels'
            },
            {
              href: '/hotel-deals/k4/Germany-Hotels.html',
              text: 'Germany Hotels'
            },
            {
              href: '/hotel-deals/k126/Ghana-Hotels.html',
              text: 'Ghana Hotels'
            },
            {
              href: '/hotel-deals/k232/Gibraltar-Hotels.html',
              text: 'Gibraltar Hotels'
            },
            {
              href: '/hotel-deals/k61/Greece-Hotels.html',
              text: 'Greece Hotels'
            },
            {
              href: '/hotel-deals/k123/Greenland-Hotels.html',
              text: 'Greenland Hotels'
            },
            {
              href: '/hotel-deals/k90/Grenada-Hotels.html',
              text: 'Grenada Hotels'
            },
            {
              href: '/hotel-deals/k187/Guadeloupe-Hotels.html',
              text: 'Guadeloupe Hotels'
            },
            {
              href: '/hotel-deals/k76/Guatemala-Hotels.html',
              text: 'Guatemala Hotels'
            },
            {
              href: '/hotel-deals/k35/Guyana-Hotels.html',
              text: 'Guyana Hotels'
            },
            {
              href: '/hotel-deals/k103/Haiti-Hotels.html',
              text: 'Haiti Hotels'
            },
            {
              href: '/hotel-deals/k78/Honduras-Hotels.html',
              text: 'Honduras Hotels'
            },
            {
              href: '/hotel-deals/k17/Hong-Kong-Hotels.html',
              text: 'Hong Kong Hotels'
            },
            {
              href: '/hotel-deals/k15/Hungary-Hotels.html',
              text: 'Hungary Hotels'
            },
            {
              href: '/hotel-deals/k112/Iceland-Hotels.html',
              text: 'Iceland Hotels'
            },
            {
              href: '/hotel-deals/k73/India-Hotels.html',
              text: 'India Hotels'
            },
            {
              href: '/hotel-deals/k23/Indonesia-Hotels.html',
              text: 'Indonesia Hotels'
            },
            {
              href: '/hotel-deals/k182/Ireland-Hotels.html',
              text: 'Ireland Hotels'
            },
            {
              href: '/hotel-deals/k62/Israel-Hotels.html',
              text: 'Israel Hotels'
            },
            {
              href: '/hotel-deals/k5/Italy-Hotels.html',
              text: 'Italy Hotels'
            },
            {
              href: '/hotel-deals/k129/Ivory-Coast-Hotels.html',
              text: 'Ivory Coast Hotels'
            },
            {
              href: '/hotel-deals/k30/Jamaica-Hotels.html',
              text: 'Jamaica Hotels'
            },
            {
              href: '/hotel-deals/k27/Japan-Hotels.html',
              text: 'Japan Hotels'
            },
            {
              href: '/hotel-deals/k208/Jordan-Hotels.html',
              text: 'Jordan Hotels'
            },
            {
              href: '/hotel-deals/k110/Kazakhstan-Hotels.html',
              text: 'Kazakhstan Hotels'
            },
            {
              href: '/hotel-deals/k125/Kenya-Hotels.html',
              text: 'Kenya Hotels'
            },
            {
              href: '/hotel-deals/k118/Kosovo-Hotels.html',
              text: 'Kosovo Hotels'
            },
            {
              href: '/hotel-deals/k66/Kuwait-Hotels.html',
              text: 'Kuwait Hotels'
            },
            {
              href: '/hotel-deals/k111/Kyrgyzstan-Hotels.html',
              text: 'Kyrgyzstan Hotels'
            },
            {
              href: '/hotel-deals/k107/Laos-Hotels.html',
              text: 'Laos Hotels'
            },
            {
              href: '/hotel-deals/k175/Latvia-Hotels.html',
              text: 'Latvia Hotels'
            },
            {
              href: '/hotel-deals/k86/Lebanon-Hotels.html',
              text: 'Lebanon Hotels'
            },
            {
              href: '/hotel-deals/k158/Lesotho-Hotels.html',
              text: 'Lesotho Hotels'
            },
            {
              href: '/hotel-deals/k57/Libya-Hotels.html',
              text: 'Libya Hotels'
            },
            {
              href: '/hotel-deals/k169/Liechtenstein-Hotels.html',
              text: 'Liechtenstein Hotels'
            },
            {
              href: '/hotel-deals/k220/Lithuania-Hotels.html',
              text: 'Lithuania Hotels'
            },
            {
              href: '/hotel-deals/k173/Luxembourg-Hotels.html',
              text: 'Luxembourg Hotels'
            },
            {
              href: '/hotel-deals/k19/Macau-Hotels.html',
              text: 'Macau Hotels'
            },
            {
              href: '/hotel-deals/k224/Macedonia-Hotels.html',
              text: 'Macedonia Hotels'
            },
            {
              href: '/hotel-deals/k163/Madagascar-Hotels.html',
              text: 'Madagascar Hotels'
            },
            {
              href: '/hotel-deals/k160/Malawi-Hotels.html',
              text: 'Malawi Hotels'
            },
            {
              href: '/hotel-deals/k22/Malaysia-Hotels.html',
              text: 'Malaysia Hotels'
            },
            {
              href: '/hotel-deals/k148/Maldives-Hotels.html',
              text: 'Maldives Hotels'
            },
            {
              href: '/hotel-deals/k52/Mali-Hotels.html',
              text: 'Mali Hotels'
            },
            {
              href: '/hotel-deals/k221/Malta-Hotels.html',
              text: 'Malta Hotels'
            },
            {
              href: '/hotel-deals/k190/Martinique-Hotels.html',
              text: 'Martinique Hotels'
            },
            {
              href: '/hotel-deals/k77/Mauritius-Hotels.html',
              text: 'Mauritius Hotels'
            },
            {
              href: '/hotel-deals/k12/Mexico-Hotels.html',
              text: 'Mexico Hotels'
            },
            {
              href: '/hotel-deals/k216/Moldova-Hotels.html',
              text: 'Moldova Hotels'
            },
            {
              href: '/hotel-deals/k233/Monaco-Hotels.html',
              text: 'Monaco Hotels'
            },
            {
              href: '/hotel-deals/k142/Montenegro-Hotels.html',
              text: 'Montenegro Hotels'
            },
            {
              href: '/hotel-deals/k58/Morocco-Hotels.html',
              text: 'Morocco Hotels'
            },
            {
              href: '/hotel-deals/k134/Mozambique-Hotels.html',
              text: 'Mozambique Hotels'
            },
            {
              href: '/hotel-deals/k75/Myanmar-Hotels.html',
              text: 'Myanmar Hotels'
            },
            {
              href: '/hotel-deals/k228/Namibia-Hotels.html',
              text: 'Namibia Hotels'
            },
            {
              href: '/hotel-deals/k116/Nepal-Hotels.html',
              text: 'Nepal Hotels'
            },
            {
              href: '/hotel-deals/k120/Netherlands-Hotels.html',
              text: 'Netherlands Hotels'
            },
            {
              href: '/hotel-deals/k200/New-Caledonia-Hotels.html',
              text: 'New Caledonia Hotels'
            },
            {
              href: '/hotel-deals/k28/New-Zealand-Hotels.html',
              text: 'New Zealand Hotels'
            },
            {
              href: '/hotel-deals/k83/Nicaragua-Hotels.html',
              text: 'Nicaragua Hotels'
            },
            {
              href: '/hotel-deals/k168/Nigeria-Hotels.html',
              text: 'Nigeria Hotels'
            },
            {
              href: '/hotel-deals/k211/Northern-Mariana-Islands-Hotels.html',
              text: 'Northern Mariana Islands Hotels'
            },
            {
              href: '/hotel-deals/k32/Norway-Hotels.html',
              text: 'Norway Hotels'
            },
            {
              href: '/hotel-deals/k212/Oman-Hotels.html',
              text: 'Oman Hotels'
            },
            {
              href: '/hotel-deals/k71/Pakistan-Hotels.html',
              text: 'Pakistan Hotels'
            },
            {
              href: '/hotel-deals/k217/Palestinian-Territories-Hotels.html',
              text: 'Palestinian Territories Hotels'
            },
            {
              href: '/hotel-deals/k53/Panama-Hotels.html',
              text: 'Panama Hotels'
            },
            {
              href: '/hotel-deals/k81/Papua-New-Guinea-Hotels.html',
              text: 'Papua New Guinea Hotels'
            },
            {
              href: '/hotel-deals/k133/Paraguay-Hotels.html',
              text: 'Paraguay Hotels'
            },
            {
              href: '/hotel-deals/k45/Peru-Hotels.html',
              text: 'Peru Hotels'
            },
            {
              href: '/hotel-deals/k25/Philippines-Hotels.html',
              text: 'Philippines Hotels'
            },
            {
              href: '/hotel-deals/k14/Poland-Hotels.html',
              text: 'Poland Hotels'
            },
            {
              href: '/hotel-deals/k59/Portugal-Hotels.html',
              text: 'Portugal Hotels'
            },
            {
              href: '/hotel-deals/k69/Qatar-Hotels.html',
              text: 'Qatar Hotels'
            },
            {
              href: '/hotel-deals/k21/Republic-of-Korea-Hotels.html',
              text: 'Republic of Korea Hotels'
            },
            {
              href: '/hotel-deals/k199/Reunion-Hotels.html',
              text: 'Reunion Hotels'
            },
            {
              href: '/hotel-deals/k105/Romania-Hotels.html',
              text: 'Romania Hotels'
            },
            {
              href: '/hotel-deals/k40/Russia-Hotels.html',
              text: 'Russia Hotels'
            },
            {
              href: '/hotel-deals/k215/Saint-Barthelemy-Hotels.html',
              text: 'Saint Barthelemy Hotels'
            },
            {
              href: '/hotel-deals/k188/Saint-Martin-French-Part--Hotels.html',
              text: 'Saint Martin (French Part) Hotels'
            },
            {
              href: '/hotel-deals/k80/Saint-Vincent-and-the-Grenadines-Hotels.html',
              text: 'Saint Vincent and the Grenadines Hotels'
            },
            {
              href: '/hotel-deals/k172/San-Marino-Hotels.html',
              text: 'San Marino Hotels'
            },
            {
              href: '/hotel-deals/k64/Saudi-Arabia-Hotels.html',
              text: 'Saudi Arabia Hotels'
            },
            {
              href: '/hotel-deals/k49/Senegal-Hotels.html',
              text: 'Senegal Hotels'
            },
            {
              href: '/hotel-deals/k119/Serbia-Hotels.html',
              text: 'Serbia Hotels'
            },
            {
              href: '/hotel-deals/k231/Seychelles-Hotels.html',
              text: 'Seychelles Hotels'
            },
            {
              href: '/hotel-deals/k183/Singapore-Hotels.html',
              text: 'Singapore Hotels'
            },
            {
              href: '/hotel-deals/k214/Sint-Maarten-Dutch-Part--Hotels.html',
              text: 'Sint Maarten (Dutch Part)'
            },
            {
              href: '/hotel-deals/k174/Slovakia-Hotels.html',
              text: 'Slovakia Hotels'
            },
            {
              href: '/hotel-deals/k171/Slovenia-Hotels.html',
              text: 'Slovenia Hotels'
            },
            {
              href: '/hotel-deals/k122/South-Africa-Hotels.html',
              text: 'South Africa Hotels'
            },
            {
              href: '/hotel-deals/k8/Spain-Hotels.html',
              text: 'Spain Hotels'
            },
            {
              href: '/hotel-deals/k24/Sri-Lanka-Hotels.html',
              text: 'Sri Lanka Hotels'
            },
            {
              href: '/hotel-deals/k36/St-Lucia-Hotels.html',
              text: 'St Lucia Hotels'
            },
            {
              href: '/hotel-deals/k189/St-Kitts-and-Nevis-Hotels.html',
              text: 'St Kitts and Nevis Hotels'
            },
            {
              href: '/hotel-deals/k131/Suriname-Hotels.html',
              text: 'Suriname Hotels'
            },
            {
              href: '/hotel-deals/k33/Sweden-Hotels.html',
              text: 'Sweden Hotels'
            },
            {
              href: '/hotel-deals/k10/Switzerland-Hotels.html',
              text: 'Switzerland Hotels'
            },
            {
              href: '/hotel-deals/k20/Taiwan-Hotels.html',
              text: 'Taiwan Hotels'
            },
            {
              href: '/hotel-deals/k95/Tajikistan-Hotels.html',
              text: 'Tajikistan Hotels'
            },
            {
              href: '/hotel-deals/k155/Tanzania-Hotels.html',
              text: 'Tanzania Hotels'
            },
            {
              href: '/hotel-deals/k26/Thailand-Hotels.html',
              text: 'Thailand Hotels'
            },
            {
              href: '/hotel-deals/k152/Togo-Hotels.html',
              text: 'Togo Hotels'
            },
            {
              href: '/hotel-deals/k91/Trinidad-and-Tobago-Hotels.html',
              text: 'Trinidad and Tobago Hotels'
            },
            {
              href: '/hotel-deals/k60/Tunisia-Hotels.html',
              text: 'Tunisia Hotels'
            },
            {
              href: '/hotel-deals/k34/Turkey-Hotels.html',
              text: 'Turkey Hotels'
            },
            {
              href: '/hotel-deals/k191/Turks-and-Caicos-Islands-Hotels.html',
              text: 'Turks and Caicos Islands Hotels'
            },
            {
              href: '/hotel-deals/k138/Uganda-Hotels.html',
              text: 'Uganda Hotels'
            },
            {
              href: '/hotel-deals/k167/Ukraine-Hotels.html',
              text: 'Ukraine Hotels'
            },
            {
              href: '/hotel-deals/k70/United-Arab-Emirates-Hotels.html',
              text: 'United Arab Emirates Hotels'
            },
            {
              href: '/hotel-deals/k6/United-Kingdom-Hotels.html',
              text: 'United Kingdom Hotels'
            },
            {
              href: '/hotel-deals/k1/United-States-Hotels.html',
              text: 'United States Hotels'
            },
            {
              href: '/hotel-deals/k46/Uruguay-Hotels.html',
              text: 'Uruguay Hotels'
            },
            {
              href: '/hotel-deals/k94/Uzbekistan-Hotels.html',
              text: 'Uzbekistan Hotels'
            },
            {
              href: '/hotel-deals/k79/Vanuatu-Hotels.html',
              text: 'Vanuatu Hotels'
            },
            {
              href: '/hotel-deals/k72/Venezuela-Hotels.html',
              text: 'Venezuela Hotels'
            },
            {
              href: '/hotel-deals/k41/Vietnam-Hotels.html',
              text: 'Vietnam Hotels'
            },
            {
              href: '/hotel-deals/k135/Zambia-Hotels.html',
              text: 'Zambia Hotels'
            },
            {
              href: '/hotel-deals/k141/Zimbabwe-Hotels.html',
              text: 'Zimbabwe Hotels'
            },
            {
              href: '',
              text: ''
            }
          ]
        }
      }
    }
  },
  packages: {
    name: 'PACKAGE',
    links: {
      exploreDeals: {
        caption: 'Explore Package Deals',
        action: 'Cheap Packages',
        items: [
          {
            href: '/deals/vacationpackages/theme-Hawaii-1790023.html',
            text: 'Hawaii Packages'
          },
          {
            href: '/deals/vacationpackages/theme-All_Inclusive-1790004.html',
            text: 'All Inclusive Packages'
          },
          {
            href: '/deals/vacationpackages/dest-United_States_Nevada_Las_Vegas-1700147.html',
            text: 'Nevada Packages'
          },
          {
            href: '/deals/vacationpackages/theme-Florida-1790022.html',
            text: 'Florida Packages'
          },
          {
            href: '/deals/vacationpackages/dest-United_States_Puerto_Rico_Puerto_Rico-1700216.html',
            text: 'Puerto Rico Packages'
          },
          {
            href: '/deals/vacationpackages/theme-Caribbean-1790021.html',
            text: 'Caribbean Packages'
          },
          {
            href: '/deals/vacationpackages/theme-Honeymoon-1790013.html',
            text: 'Honeymoon Packages'
          },
          {
            href: '/deals/vacationpackages/dest-Bahamas_Bahamas-1700323.html',
            text: 'Bahamas Packages'
          },
          {
            href: '/deals/vacationpackages/dest-United_States_Nevada_Las_Vegas-1700147.html',
            text: 'Las Vegas Packages'
          },
          {
            href: '/deals/vacationpackages/dest-United_States_South_Carolina_Myrtle_Beach-1700182.html',
            text: 'Myrtle Beach Packages'
          },
          {
            href: '/deals/vacationpackages/dest-United_States_Florida_Orlando-1700197.html',
            text: 'Orlando Packages'
          },
          {
            href: '/deals/vacationpackages/dest-Mexico_Cancun-1700057.html',
            text: 'Cancun Packages'
          }
        ]
      }
    }
  }
}
